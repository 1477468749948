import React from 'react'

const LibraryPanel = () => {
  return (
      <div>
       
          <div className="text-white p-5 fs-1">Library Panel Content Goes Here...</div>
      </div>
  );
}

export default LibraryPanel