import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import useSatoshi from "../../hooks/useSatoshi";
import coinImg1 from "../../icons/coinImg1.svg";
import BitcoinIcon from "../../assests/icons/BTC.svg";
import EthcoinIcon from "../../assests/icons/ETH.svg";
import ADAcoinIcon from "../../assests/icons/ADA.svg";
import BNBcoinIcon from "../../assests/icons/BNB.svg";
import BTXcoinIcon from "../../assests/icons/BTX.png";
import BUSDcoinIcon from "../../assests/icons/BUSD.svg";
import COMPcoinIcon from "../../assests/icons/COMP.svg";
import DMTcoinIcon from "../../assests/icons/DMT.svg";
import DTAcoinIcon from "../../assests/icons/DTA.svg";
import SOLcoinIcon from "../../assests/icons/SOL.svg";
import USDTcoinIcon from "../../assests/icons/USDT.svg";
import USDCcoinIcon from "../../assests/icons/USDC.svg";
import XRPcoinIcon from "../../assests/icons/XRP.svg";



const DataTable = () => {
  const { email } = useSatoshi();

  // const [tableData, setTableData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const tableData = [
    {
      coinIcon: BitcoinIcon,
      coinName: "Bitcoin (BTC)",
      coinPrice: "46095",
      quantity: "0.00103",
    },
    {
        coinIcon: EthcoinIcon,
        coinName: "Ethereum (ETH)",
        coinPrice: "4576",
        quantity: "0.0657",
      },{
        coinIcon: USDTcoinIcon,
        coinName: "Tether (USDT)",
        coinPrice: "4568",
        quantity: "0.036",
      },{
        coinIcon: BNBcoinIcon,
        coinName: "BNB (BNB)",
        coinPrice: "275",
        quantity: "0.089",
      },{
        coinIcon: USDCcoinIcon,
        coinName: "USD Coin (USDC)",
        coinPrice: "32567",
        quantity: "0.05",
      },{
        coinIcon: XRPcoinIcon,
        coinName: "XRP (XRP)",
        coinPrice: "275",
        quantity: "0.089",
      },{
        coinIcon: SOLcoinIcon,
        coinName: "Solana (SOL)",
        coinPrice: "32567",
        quantity: "0.05",
      },{
        coinIcon: ADAcoinIcon,
        coinName: "Cardano (ADA)",
        coinPrice: "275",
        quantity: "0.089",
      },{
        coinIcon: BUSDcoinIcon,
        coinName: "Binance USD (BUSD)",
        coinPrice: "32567",
        quantity: "0.05",
      },{
        coinIcon: COMPcoinIcon,
        coinName: "Compound (COMP)",
        coinPrice: "275",
        quantity: "0.089",
      },{
        coinIcon: DTAcoinIcon,
        coinName: "DATA (DATA)",
        coinPrice: "32567",
        quantity: "0.05",
      },
      {
        coinIcon: BTXcoinIcon,
        coinName: "BitCore (BTX)",
        coinPrice: "275",
        quantity: "0.089",
      },
      {
        coinIcon: DMTcoinIcon,
        coinName: "DMarket (DMT)",
        coinPrice: "32567",
        quantity: "0.05",
      },
  ];

  //   useEffect(()=>{
  //     setIsLoading(true);
  //     fetch('https://satoshi-api.onrender.com/table8')
  //     .then(res=>res.json())
  //     .then(data => {
  //       setTableData(data);
  //       setIsLoading(false);
  //     });
  //   },[])

  return (
    <>
      <div className="tableWrapper">
        <h1 className="tableHeading m-0 mb-4">Coin on Hand</h1>
        <div className="cointable">
          <div className=" table-responsive">
            <Table striped hover>
              <thead>
                <tr>
                  <th className=" text-nowrap">Coin Name</th>
                  <th className=" text-nowrap">Coin Price</th>
                  <th className=" text-nowrap">QTY</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item, index) => (
                  <tr key={index}>
                    <td className="d-flex align-items-center gap-2 text-nowrap">
                      <img src={item?.coinIcon} alt="icon" /> <span>{item?.coinName}</span>
                    </td>
                    <td>{item?.coinPrice}</td>
                    <td>{item?.quantity}</td>
                  </tr>
                ))}
              </tbody>
              {/* {
                                    isLoading &&  <div className='text-center'>
                                        <Spinner className='my-4' animation="grow" variant="warning" />
                                    </div>
                                } */}
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataTable;
