import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import { Alert } from "bootstrap";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import MultiStepForm from "./components/AccountSetup/MultiStepForm/MultiStepForm";
import FailedStep from "./components/AccountSetup/Step/FailedStep/FailedStep";
import StepFinal from "./components/AccountSetup/Step/SuccessStep/StepFinal";
import ConfirmPass from "./components/ConfirmPass/ConfirmPass";
import CreateAccount from "./components/CreateAccount/CreateAccount";
import Documantation from "./components/Documantation/Documantation";
import ForgetPass from "./components/ForgetPass/ForgetPass";
import Login from "./components/Login/Login";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import Settings from "./components/Settings/Settings";
import SideBar from "./components/SideBar/SideBar";
import AuthProvider from "./context/AuthProvider";
import AlertGroup from "./pages/Alert/AlertGroup";
import Alertrule from "./pages/Alert/Alertrule";
import Silence from "./pages/Alert/Silence";
import Configaration from "./pages/Configaration/Configaration";
import Browse from "./pages/Dashboard/Bowse";
import Dashboard from "./pages/Dashboard/Dashboard";
import LibraryPanel from "./pages/Dashboard/LibraryPanel";
import Playlist from "./pages/Dashboard/Playlist";
import Snapshort from "./pages/Dashboard/Snapshort";
import { useEffect } from "react";

function App() {
  //  basename="/satoshi"

  const [menuOpen, setMenuOpen] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  return (
    <AuthProvider>
      {loading ? (
        <div style={{height:"100vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <Spinner animation="grow" variant="warning" />
        </div>
      ) : (
        <BrowserRouter basename="/">
          <div className="main-container">
            <SideBar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            <div className={"main-wrapper " + (menuOpen && "overLay")}>
              <NavigationBar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
              <Routes>
                <Route
                  path={"/"}
                  element={
                    <Dashboard menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                  }
                ></Route>
                <Route
                  path={"/dashboard"}
                  element={
                    <Dashboard menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                  }
                ></Route>
                {/*<Route path={"/admincontrol"} element={<AdminControl/>}/>*/}
                <Route
                  path={"/configaration"}
                  element={<Configaration />}
                ></Route>
                <Route
                  path={"/documantation"}
                  element={<Documantation />}
                ></Route>
                <Route path={"/login"} element={<Login />}></Route>
                <Route path={"/setting"} element={<Settings />}></Route>
                <Route
                  path={"/create_account"}
                  element={<CreateAccount></CreateAccount>}
                ></Route>
                <Route
                  path={"/forget_password"}
                  element={<ForgetPass />}
                ></Route>
                <Route
                  path={"/confirm_password"}
                  element={<ConfirmPass></ConfirmPass>}
                ></Route>
                <Route path={"/multi_step_form"} element={<MultiStepForm />} />
                <Route path={"/final_step"} element={<StepFinal />} />
                <Route path={"/failed_step"} element={<FailedStep />} />
                <Route path={"/browse"} element={<Browse />} />
                <Route path={"/playlist"} element={<Playlist />} />
                <Route path={"/libraryPanel"} element={<LibraryPanel />} />
                <Route path={"/snapshots"} element={<Snapshort />} />
                <Route path={"/alert"} element={<Alert />} />
                <Route path={"/alertgroup"} element={<AlertGroup />} />
                <Route path={"/alertrule"} element={<Alertrule />} />
                <Route path={"/silence"} element={<Silence />} />
              </Routes>
            </div>
          </div>
        </BrowserRouter>
      )}
    </AuthProvider>
  );
}

export default App;
