import React from 'react'

const Alertrule = () => {
  return (
      <div>
           <div className="text-white p-5 fs-1">Alert Rule Content Goes Here...</div>
      </div>
  );
}

export default Alertrule