import React from 'react'

const Playlist = () => {
  return (
      <div>
          <div className="text-white p-5 fs-1">Playlists Content Goes Here...</div>
      </div>
  );
}

export default Playlist