import React from 'react'

const Snapshort = () => {
  return (
      <div>
       
          <div className="text-white p-5 fs-1">Snapshots Content Goes Here...</div>
      </div>
  );
}

export default Snapshort